import { createAxiosMutation } from "@/hooks/react-query";
import { apiClient } from "@/lib/api";
import { API_BASE_PATH, BaseQueryClient } from "@/modules/base/queries";
import { QueryKeys } from "@/types";

import { SiteImport } from "./types";

const queryKey = QueryKeys.SiteImports;

class SiteImportQueryClient extends BaseQueryClient<SiteImport> {
  constructor() {
    super({ queryKey });

    this.useCreateMutation = createAxiosMutation(
      async ({ values }: { values: Partial<SiteImport> }) => {
        const formData = new FormData();
        formData.append("import", values.file);
        formData.append("site_id", `${values.site_id}`);

        const { data } = await apiClient.post<Partial<SiteImport>>(
          `${API_BASE_PATH}/${this.queryKey}`,
          formData,
          { headers: { "Content-Type": "text/csv" } }
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: this.createMessageSuccess(),
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: this.createMessageFailure(err),
        }),
        onSettled: async (queryClient, _result, _err, _id) => {
          await queryClient.invalidateQueries([this.queryKey]);
        },
      }
    );
  }
}
export const SiteImportQueries = new SiteImportQueryClient();
