import { QueryKeys } from "@/types";

import { apiClient } from "@/lib/api";

import { createAxiosMutation } from "@/hooks/react-query";

import { getErrorMessage } from "@/lib/axios-helpers";

import { API_BASE_PATH } from "../base/queries";

import { ElevatorAccessLevelPayload } from "./types";

interface CreateElevatorAccessLevelMutation {
  values: ElevatorAccessLevelPayload;
}
export const useCreateElevatorAccessLevelMutation = createAxiosMutation(
  async ({ values }: CreateElevatorAccessLevelMutation) => {
    const { data } = await apiClient.post<ElevatorAccessLevelPayload>(
      `${API_BASE_PATH}/${QueryKeys.ElevatorAccessLevels}`,
      values
    );
    return data;
  },
  {
    successToast: () => ({
      status: "success",
      message: "Created Successfully.",
    }),
    errorToast: (err: any) => ({
      status: "error",
      message: `Failed To Create. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient, _result, _err, _id) => {
      await queryClient.invalidateQueries([QueryKeys.Elevators]);
      await queryClient.invalidateQueries([QueryKeys.ElevatorAccessLevels]);
      await queryClient.invalidateQueries([QueryKeys.AccessLevels]);
      await queryClient.invalidateQueries([QueryKeys.AccessLevelSchedules]);
      await queryClient.invalidateQueries([QueryKeys.Floors]);
    },
  }
);

interface UpdateElevatorAccessLevelsMutation {
  values: ElevatorAccessLevelPayload;
}
export const useUpdateElevatorAccessLevelsMutation = createAxiosMutation(
  async ({ values }: UpdateElevatorAccessLevelsMutation) => {
    const { data } = await apiClient.post<ElevatorAccessLevelPayload>(
      `${API_BASE_PATH}/${QueryKeys.ElevatorAccessLevels}`,
      values
    );
    return data;
  },
  {
    successToast: () => ({
      status: "success",
      message: "Updated Successfully.",
    }),
    errorToast: (err: any) => ({
      status: "error",
      message: `Failed To Update. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient, _result, _err, _id) => {
      await queryClient.invalidateQueries([QueryKeys.Elevators]);
      await queryClient.invalidateQueries([QueryKeys.ElevatorAccessLevels]);
      await queryClient.invalidateQueries([QueryKeys.AccessLevels]);
      await queryClient.invalidateQueries([QueryKeys.AccessLevelSchedules]);
      await queryClient.invalidateQueries([QueryKeys.Floors]);
    },
  }
);

interface DeleteElevatorAccessLevelQueryProps {
  access_level_id: number;
  door_id: number;
}
export const useDeleteElevatorAccessLevelQuery = createAxiosMutation(
  async ({ access_level_id, door_id }: DeleteElevatorAccessLevelQueryProps) => {
    const { data } = await apiClient.delete<ElevatorAccessLevelPayload>(
      `${API_BASE_PATH}/${QueryKeys.ElevatorAccessLevels}`,
      { params: { access_level_id, door_id } }
    );
    return data;
  },
  {
    successToast: () => ({
      status: "success",
      message: "Delete Successful.",
    }),
    errorToast: (err: any) => ({
      status: "error",
      message: `Failed To Delete. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient, _result, _err, _id) => {
      await queryClient.invalidateQueries([QueryKeys.Elevators]);
      await queryClient.invalidateQueries([QueryKeys.ElevatorAccessLevels]);
      await queryClient.invalidateQueries([QueryKeys.AccessLevels]);
      await queryClient.invalidateQueries([QueryKeys.AccessLevelSchedules]);
    },
  }
);
