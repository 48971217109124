import { QueryKeys } from "@/types";

interface PathParams {
  site_id?: number;
  id?: number;
}

export const Paths = {
  organizationsListPath: () => "/superadmin",
  organizationViewPath: (organizationId: string | number) =>
    `/superadmin/organizations/${organizationId}`,
  featureFlagListPath: () => "/superadmin/feature-flags",
  firmwareListPath: () => "/superadmin/firmware",
  siteViewPath: (site_id: string | number) => `/sites/${site_id}`,
  controllerViewPath: (
    site_id: string | number,
    controller_id: string | number
  ) => `/sites/${site_id}/controllers/${controller_id}`,
  controllerLogsPath: (
    site_id: string | number,
    controller_id: string | number
  ) => `/sites/${site_id}/controllers/${controller_id}/logs`,
  controllerDebugPath: (
    site_id: string | number,
    controller_id: string | number
  ) => `/sites/${site_id}/controllers/${controller_id}/debug`,
  controllersListPath: (site_id: string | number) =>
    `/sites/${site_id}/controllers`,
  doorsListPath: (site_id: string | number) => `/sites/${site_id}/doors`,
  doorViewPath: (site_id: string | number, door_id: string | number) =>
    `/sites/${site_id}/doors/${door_id}`,
  elevatorsListPath: (site_id: string | number) =>
    `/sites/${site_id}/doors?tab=elevators`,
  devicesListPath: (site_id: string | number) => `/sites/${site_id}/devices`,
  eventsListPage: (site_id: string | number) => `/sites/${site_id}/events`,
  userActivityLogsPage: (site_id: string | number) =>
    `/sites/${site_id}/user-activity-logs`,
  cardsListPath: (site_id: string | number) => `/sites/${site_id}/cards`,
  holidayGroupListPath: (site_id: string | number) =>
    `/sites/${site_id}/exception-groups`,
  holidayGroupViewPath: (
    site_id: string | number | undefined,
    holiday_group_id: string | number | undefined
  ) => `/sites/${site_id}/exception-groups/${holiday_group_id}`,
  hubViewPath: (site_id: string | number, hubId: string | number) =>
    `/sites/${site_id}/hubs/${hubId}`,
  hubsListPath: (site_id: string | number) => `/sites/${site_id}/hubs`,
  apiApplicationsListPath: (site_id: string | number) =>
    `/sites/${site_id}/api-clients`,
  schedulesListPath: (site_id: string | number) =>
    `/sites/${site_id}/schedules`,
  schedulesViewPath: (site_id: string | number, scheduleId: string | number) =>
    `/sites/${site_id}/schedules/${scheduleId}`,
  siteSettingsPath: (site_id: string | number) => `/sites/${site_id}/settings`,
  siteSettingsGeneralPath: (site_id: string | number) =>
    `/sites/${site_id}/settings/general`,
  siteSettingsFirmwarePath: (site_id: string | number) =>
    `/sites/${site_id}/settings/firmware`,
  siteSettingsNotificationsPath: (site_id: string | number) =>
    `/sites/${site_id}/settings/site-notifications`,
  membersListPage: (site_id: string | number) => `/sites/${site_id}/members`,
  memberViewPage: (site_id: string | number, member_id: string | number) =>
    `/sites/${site_id}/members/${member_id}`,
  usersListPage: () => `/admin/users`,
  scheduleViewPage: (site_id: string | number, schedule_id: string | number) =>
    `/sites/${site_id}/schedules/${schedule_id}`,
  importsListPage: (site_id: string | number) => `/sites/${site_id}/imports`,
  accessLevelsListPath: (site_id: string | number) =>
    `/sites/${site_id}/access-levels`,
  accessLevelShowPath: (
    site_id: string | number,
    access_level_id: string | number
  ) => `/sites/${site_id}/access-levels/${access_level_id}`,
  systemHealthPath: (site_id: string | number) =>
    `/sites/${site_id}/system-health`,

  GetPath: (
    queryKey: QueryKeys | string,
    action: PathActions,
    params: PathParams,
    qsParams?: Record<string, string>
  ) => {
    let basePath = "";
    if (params?.site_id) {
      basePath += `/sites/${params.site_id}`;
    }
    switch (action) {
      case PathActions.List:
        basePath = `${basePath}/${queryKey}/`;
        break;
      case PathActions.View:
        basePath = `${basePath}/${queryKey}/${params.id}`;
        break;
    }
    if (qsParams) {
      const queryString = new URLSearchParams(qsParams).toString();
      basePath = `${basePath}?${queryString}`;
    }
    return basePath;
  },
  BuildViewPath: (
    pathBuilder: (
      site_id: string | number | undefined,
      resource_id: string | number | undefined
    ) => string,
    params: PathParams,
    qsParams?: Record<string, string>
  ) => {
    let basePath = pathBuilder(params.site_id, params.id);
    if (qsParams) {
      const queryString = new URLSearchParams(qsParams).toString();
      basePath = `${basePath}?${queryString}`;
    }
    return basePath;
  },
};
export enum PathActions {
  View = "view",
  List = "list",
}
