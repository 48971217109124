import { format, isValid, parseISO } from "date-fns";
import { toDate, formatInTimeZone } from "date-fns-tz";

export const TIME_FORMAT = "hh:mm a";
export const DATE_FORMAT = "MM/dd/yyyy";
export const DATE_FORMAT_SHORT = "MM/dd/yy";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

/**
 * Use this function to get a date back when passing in a time
 *
 * @param {string} time - A time in ISO8601 Format (e.g. "16:36:29")
 * @returns {object} Date - A js Date object
 */
function getTimeAsDate(time?: string | null): Date | null {
  if (!time) return null;
  const parts = time.split(":");
  if (!parts || parts.length != 3) return null;

  const date = new Date();
  date.setHours(Number(parts[0]));
  date.setMinutes(Number(parts[1]));
  date.setSeconds(Number(parts[2]));
  return date;
}

/**
 * Use this function to consistently display times across the app.
 *
 * @param {string} time - A time in ISO8601 Format (e.g. "16:36:29")
 * @returns {string} A nicely formatted time (e.g. "4:36 PM")
 */
export function formatTime(time?: string | null) {
  if (!time) {
    return "";
  }
  const date = getTimeAsDate(time);
  if (!date) {
    return "";
  }
  return format(date, TIME_FORMAT);
}

/**
 * This function is used to format and display datetime values consistently across the application.
 *
 * @param {string} dateTime - The datetime value to be formatted. It should be in ISO8601 format (e.g. "2019-02-25T16:36:29Z").
 * @param {string} timezone - The timezone to which the datetime should be converted. It should be in the format "America/Chicago". If not provided, UTC is used.
 * @param {string} formatPattern - The pattern to be used for formatting the datetime. Default is "PPPPpp".
 * @returns {string} The formatted datetime string. If the input datetime is not valid, an empty string is returned.
 */
export function formatDateTime(
  dateTime?: string,
  timezone = "",
  formatPattern = "PPPPpp"
) {
  // Validate the input datetime. If it's not valid, return an empty string.
  if (!dateTime || !isValid(new Date(dateTime))) {
    return "";
  }

  // The datetime is parsed to UTC because the alloy_access database stores all times in UTC.
  // The database uses two different DateTime standards:
  // 1. "2022-02-09T16:36:29Z" - This is a UTC time (DateTime in Elixir).
  // 2. "2023-08-03 23:39:25" - This is a time without a timezone (NaiveDateTime in Elixir).
  const parsedDate = toDate(dateTime, { timeZone: "UTC" });

  // The parsed datetime is then formatted to the specified timezone. If no timezone is provided, UTC is used.
  return formatInTimeZone(parsedDate, timezone, formatPattern);
}

/**
 * Use this function to consistently display datetimes across the app.
 *
 * @param {string} dateTime - A datetime in ISO8601 Format (e.g. "2019-02-25T16:36:29Z")
 * @returns {string} A nicely formatted dateTime (e.g. "4:36 PM")
 */
export function formatTimeFromDateTime(dateTime?: string | null) {
  if (!dateTime) {
    return "";
  }

  return format(parseISO(dateTime), TIME_FORMAT);
}

/**
 * Use this function to consistently display datetimes across the app.
 *
 * @param {string} dateTime - A datetime in ISO8601 Format (e.g. "2019-02-25T16:36:29Z")
 * @returns {string} A nicely formatted date (e.g. "02/09/2022")
 */
export function formatDateFromDateTime(dateTime?: string | null) {
  if (!dateTime) {
    return "";
  }

  return format(parseISO(dateTime), DATE_FORMAT);
}

/**
 * Use this function to format times correctly for api input from
 * @smartrent/forms FormTimeInputField
 *
 * @param {string} time - A nicely formatted time (e.g. "4:36 PM")
 * @returns {string} time in ISO8601 Format (e.g. "16:36:29")
 */
export function toIso8601TimeFromFormTimeInput(time: {
  hour: number;
  minute: number;
}): string {
  const minutes = time.minute < 10 ? `0${time.minute}` : time.minute;
  return `${time.hour}:${minutes}:00`;
}
