import { QueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/types";

import { createAxiosMutation } from "@/hooks/react-query";

import { apiClient } from "@/lib/api";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { Door } from "../door/types";

const queryKey = QueryKeys.Elevators;

class ElevatorQueryClient extends BaseQueryClient<Door> {
  constructor() {
    super({
      afterModify: async (queryClient: QueryClient) => {
        await queryClient.invalidateQueries([QueryKeys.ElevatorAccessLevels]);
        await queryClient.invalidateQueries([QueryKeys.Floors]);
      },
      queryKey,
    });

    this.useUpdateMutation = createAxiosMutation(
      async ({ id, values }: { id: number; values: Door }) => {
        // Exclude floors from /elevators/:id updates to prevent overwriting door.floors association in backend
        const { floors: _, ...updateValues } = values;
        const { data } = await apiClient.patch<Door>(
          `${API_BASE_PATH}/${this.queryKey}/${id}`,
          this.encode(updateValues)
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: this.updateMessageSuccess(),
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: this.updateMessageFailure(err),
        }),
        onSettled: async (queryClient, result, err, id) => {
          await queryClient.invalidateQueries([this.queryKey, { id }]);
          await queryClient.invalidateQueries([this.queryKey]);
          if (this.afterModify) {
            await this.afterModify(queryClient);
          }
        },
      }
    );
  }
}
export const ElevatorQueries = new ElevatorQueryClient();
