import { intervalToDuration } from "date-fns";

import { pluralizeLabel } from "@/lib/helpers";

export const secondsToFormattedMinutes = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  if (duration.minutes == 0) {
    // @ts-expect-error: The Duration type (from date-fns) has the minutes property
    // typed as possibly undefined, but we know it will be defined here
    return `${duration.seconds} ${pluralizeLabel("second", duration.seconds)}`;
  } else if (duration.seconds == 0) {
    // @ts-expect-error: Same as above
    return `${duration.minutes} ${pluralizeLabel("minute", duration.minutes)}`;
  } else {
    return `${duration.minutes} ${pluralizeLabel(
      "minute",
      // @ts-expect-error: Same as above
      duration.minutes
      // @ts-expect-error: Same as above
    )} and ${duration.seconds} ${pluralizeLabel("second", duration.seconds)}`;
  }
};

export const someAreTrue = (object: object): boolean =>
  Object.values(object).some(Boolean);
