import { createAxiosMutation } from "@/hooks/react-query";
import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import { QueryKeys } from "@/types";

import { MemberAccessLevel } from "../access-level/types";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";
import { MemberDoors } from "../door/types";

import { Member } from "./types";

const queryKey = QueryKeys.Members;

class MemberQueryClient extends BaseQueryClient<Member> {
  public useAssignAccessLevelsMutation;
  public useUnassignAccessLevelMutation;

  public useAssignDoorsMutation;
  public useUnassignDoorMutation;

  constructor() {
    super({ queryKey });

    this.useAssignAccessLevelsMutation = createAxiosMutation(
      async (memberAccessLevel: MemberAccessLevel) => {
        const { data } = await apiClient.put(
          `${API_BASE_PATH}/member-access-levels`,
          memberAccessLevel
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: "Successfully Updated Access Levels",
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Update Access Levels. ${getErrorMessage(err)}`,
        }),
        onSettled: async (queryClient, result, err, { member_id }) => {
          // TODO: Filter this query better.
          await queryClient.invalidateQueries([QueryKeys.AccessLevels]);
          await queryClient.invalidateQueries([
            QueryKeys.Members,
            { id: member_id },
          ]);
        },
      }
    );

    this.useUnassignAccessLevelMutation = createAxiosMutation(
      async ({
        member_id,
        access_level_id,
      }: {
        member_id: number;
        access_level_id: number;
      }) => {
        const { data } = await apiClient.delete(
          `${API_BASE_PATH}/member-access-levels/${member_id}/${access_level_id}`
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: "Successfully Removed Access Level.",
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Remove Access Level. ${getErrorMessage(err)}`,
        }),
        onSettled: async (queryClient, result, err, { member_id }) => {
          // TODO: Filter this query better.
          await queryClient.invalidateQueries([QueryKeys.AccessLevels]);
          await queryClient.invalidateQueries([
            QueryKeys.Members,
            { id: member_id },
          ]);
        },
      }
    );

    this.useAssignDoorsMutation = createAxiosMutation(
      async (memberDoor: MemberDoors) => {
        const { data } = await apiClient.put(
          `${API_BASE_PATH}/member-doors`,
          memberDoor
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: "Successfully Updated Doors",
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Update Doors. ${getErrorMessage(err)}`,
        }),
        onSettled: async (queryClient, result, err, { member_id }) => {
          // TODO: Filter this query better.
          await queryClient.invalidateQueries([QueryKeys.Doors]);
          await queryClient.invalidateQueries([
            QueryKeys.Members,
            { id: member_id },
          ]);
        },
      }
    );

    this.useUnassignDoorMutation = createAxiosMutation(
      async ({
        member_id,
        door_id,
      }: {
        member_id: number;
        door_id: number;
      }) => {
        const { data } = await apiClient.delete(
          `${API_BASE_PATH}/member-doors/${member_id}/${door_id}`
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: "Successfully Removed Door.",
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Remove Door. ${getErrorMessage(err)}`,
        }),
        onSettled: async (queryClient, result, err, { member_id }) => {
          // TODO: Filter this query better.
          await queryClient.invalidateQueries([QueryKeys.Doors]);
          await queryClient.invalidateQueries([
            QueryKeys.Members,
            { id: member_id },
          ]);
        },
      }
    );
  }
}
export const MemberQueries = new MemberQueryClient();
