import { createDrawer } from "@/context/DrawerManager";
import { FirmwareDrawer } from "@/modules/firmware/Drawer";
import { SiteImportDrawer } from "@/modules/site-import/Drawer";
import { QueryKeys } from "@/types";
const {
  Provider: AppDrawer,
  useDrawerNav: useAppDrawer
} = createDrawer("AppDrawer", {
  routes: {
    // firmware and site imports need to still use Formik because we aren't
    // ready to switch to Uploadcare as a file uploader/provider yet
    [QueryKeys.Firmware]: {
      component: FirmwareDrawer
    },
    [QueryKeys.SiteImports]: {
      component: SiteImportDrawer
    }
  }
});
export { AppDrawer, useAppDrawer };