import {
  QueryClient,
  UseMutateAsyncFunction,
  UseMutationResult,
} from "@tanstack/react-query";

import { QueryKeys } from "@/types";

import { apiClient } from "@/lib/api";

import {
  CreateMutationOptions,
  createAxiosMutation,
} from "@/hooks/react-query";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { Door } from "../door/types";

import { Elevator } from "../elevator/types";

import { Floor } from "./types";

const queryKey = QueryKeys.Floors;

interface AddFloorsMutationValues {
  door_id: Door["id"];
  number_of_floors: number;
}

class FloorQueryClient extends BaseQueryClient<Floor> {
  public useAddFloorsMutation: (
    options?: CreateMutationOptions<
      Elevator,
      any,
      { values: AddFloorsMutationValues },
      unknown
    >
  ) => readonly [
    UseMutateAsyncFunction<
      Elevator,
      any,
      { values: AddFloorsMutationValues },
      unknown
    >,
    UseMutationResult<
      Elevator,
      any,
      { values: AddFloorsMutationValues },
      unknown
    >,
  ];

  constructor() {
    super({
      afterModify: async (queryClient: QueryClient) => {
        await queryClient.invalidateQueries([QueryKeys.ElevatorAccessLevels]);
        await queryClient.invalidateQueries([QueryKeys.Elevators]);
      },
      queryKey,
    });

    this.useAddFloorsMutation = createAxiosMutation(
      async ({ values }: { values: AddFloorsMutationValues }) => {
        const { data } = await apiClient.post<AddFloorsMutationValues>(
          `${API_BASE_PATH}/${this.queryKey}`,
          this.encode(values)
        );
        // POST /floors with 'number_of_floors' param returns elevator object, otherwise a list of floors.
        return data as unknown as Elevator;
      },
      {
        successToast: () => ({
          status: "success",
          message: this.createMessageSuccess(),
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: this.createMessageFailure(err),
        }),
        onSettled: async (queryClient, _result, _err, _id) => {
          await queryClient.invalidateQueries([this.queryKey]);
          if (this.afterModify) {
            await this.afterModify(queryClient);
          }
        },
      }
    );
  }
}
export const FloorQueries = new FloorQueryClient();
