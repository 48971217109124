import { createAxiosMutation } from "@/hooks/react-query";
import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import { QueryKeys } from "@/types";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import {
  Credential,
  CredentialController,
  CredentialDurationOptions,
} from "./types";

const queryKey = QueryKeys.Credentials;

class CredentialQueryClient extends BaseQueryClient<Credential> {
  public useEncodeCredentialMutation;
  public useSyncCredentialMutation;
  public useLostCredentialMutation;

  constructor() {
    super({ queryKey });

    this.useCreateMutation = createAxiosMutation(
      async ({ values }: { values: Partial<Credential> }) => {
        const { data } = await apiClient.post<Partial<Credential>>(
          `${API_BASE_PATH}/${this.queryKey}`,
          this.encode(values)
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: this.createMessageSuccess(),
        }),
        errorToast: (err: any) => ({
          status: "error",
          message: this.createMessageFailure(err),
        }),
        onSettled: async (queryClient, _result, _err, _id) => {
          await queryClient.invalidateQueries([this.queryKey]);
        },
      }
    );

    this.useEncodeCredentialMutation = createAxiosMutation(
      async (credential: Credential) => {
        const { data } = await apiClient.post(
          `${API_BASE_PATH}/${this.queryKey}/${credential.id}/encode`,
          credential
        );
        return data;
      },
      {
        onSettled: async (queryClient, result, err, { id }) => {
          // TODO: Filter this query better.
          await queryClient.invalidateQueries([this.queryKey]);
          await queryClient.invalidateQueries([this.queryKey, { id }]);
        },
      }
    );

    this.useLostCredentialMutation = createAxiosMutation(
      async (credential: Credential) => {
        const { data } = await apiClient.delete(
          `${API_BASE_PATH}/${this.queryKey}/${credential.id}/lost`
        );
        return data;
      },
      {
        successToast: () => ({
          status: "success",
          message: "Successfully marked the credential as lost",
        }),
        errorToast: () => ({
          status: "error",
          message: "Failed to mark the credential as lost",
        }),
        onSettled: async (queryClient, result, err, { id }) => {
          await queryClient.invalidateQueries([this.queryKey]);
          await queryClient.invalidateQueries([this.queryKey, { id }]);
        },
      }
    );

    this.useSyncCredentialMutation = createAxiosMutation(
      async ({
        credential,
        credentialController,
      }: {
        credential: Credential;
        credentialController?: CredentialController;
      }) => {
        const { data } = await apiClient.post(
          `${API_BASE_PATH}/${this.queryKey}/${credential.id}/sync`,
          { controller_id: credentialController?.controller_id }
        );
        return data;
      },
      {
        successToast: ({ sync_count }) => {
          return {
            status: "success",
            message: `${sync_count} Credential Sync${
              sync_count !== 1 ? "s" : ""
            } Enqueued`,
          };
        },
        errorToast: (err: any) => ({
          status: "error",
          message: `Unable To Sync Credential. ${getErrorMessage(err)}`,
        }),
        onSettled: async (queryClient, result, err, { credential: { id } }) => {
          // TODO: Filter this query better.
          await queryClient.invalidateQueries([this.queryKey]);
          await queryClient.invalidateQueries([this.queryKey, { id }]);
        },
      }
    );
  }

  encode = (values: Partial<Credential>) => {
    // We are defaulting the start and end at to make them more usable,
    // but when we are making perm creds, we need to pass those values as nil.
    if (values.duration_type === CredentialDurationOptions.permanent) {
      delete values.start_at;
      delete values.end_at;
    }
    return values;
  };
}
export const CredentialQueries = new CredentialQueryClient();
