import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { apiClient } from "@/lib/api";
import { getErrorMessage } from "@/lib/axios-helpers";
import { QueryKeys } from "@/types";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";
import { Alert } from "../alerts/types";

import { Site } from "./types";

const queryKey = QueryKeys.Sites;

class SiteQueryClient extends BaseQueryClient<Site> {
  constructor() {
    super({ queryKey });
  }

  useStartSyncMutation = createAxiosMutation(
    async ({ site_id, force }: { site_id: number; force: boolean }) => {
      const { data } = await apiClient.post(`/v1/sites/${site_id}/sync`, {
        force,
      });
      return data;
    },
    {
      successToast: () => ({
        status: "success",
        message: "Sync Started. This may take some time.",
      }),
      errorToast: (err: any) => ({
        status: "error",
        message: `Unable to start sync. ${getErrorMessage(err)}`,
      }),
    }
  );

  async getAlerts(siteId: number) {
    const { data } = await apiClient.get<Alert[]>(
      `${API_BASE_PATH}/${queryKey}/${siteId}/alerts`
    );

    return data;
  }

  getAlertsQuery = createAxiosQuery(
    QueryKeys.SiteAlerts,
    async (siteId: number | undefined) => {
      const { data } = await apiClient.get<Alert[]>(
        `${API_BASE_PATH}/${queryKey}/${siteId}/alerts`
      );

      return data;
    }
  );

  useLockdownMutation = createAxiosMutation(
    async ({
      site_id,
      action,
    }: {
      site_id: number;
      action: "activate" | "deactivate";
    }) => {
      const { data } = await apiClient.post(
        `${API_BASE_PATH}/${queryKey}/${site_id}/lockdown/${action}`
      );
      return data;
    },
    {
      successToast: () => ({
        status: "success",
        message: "Lockdown action successful",
      }),
      errorToast: (err: any) => ({
        status: "error",
        message: `Lockdown action failed. ${getErrorMessage(err)}`,
      }),
      onSettled: async (queryClient, _result, _err, { site_id }) => {
        await queryClient.invalidateQueries([queryKey]);
        await queryClient.invalidateQueries([queryKey, { id: site_id }]);
        await queryClient.invalidateQueries([QueryKeys.Events]);
      },
    }
  );
}
export const SiteQueries = new SiteQueryClient();
