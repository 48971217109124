import { toIso8601TimeFromFormTimeInput } from "@/lib/formatters";
import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { ScheduleInterval } from "./types";

const queryKey = QueryKeys.ScheduleInterval;

class ScheduleIntervalQueryClient extends BaseQueryClient<ScheduleInterval> {
  constructor() {
    super({ queryKey });
  }
  encode = (values: any) => {
    values.start_time = toIso8601TimeFromFormTimeInput(values.start_time);
    values.end_time = toIso8601TimeFromFormTimeInput(values.end_time);

    return values;
  };

  decode = (values: any) => {
    const [startHour, startMinute] = values.start_time.split(":");
    const [endHour, endMinute] = values.end_time.split(":");
    values.start_time = {
      hour: parseFloat(startHour),
      minute: parseFloat(startMinute),
    };
    values.end_time = {
      hour: parseFloat(endHour),
      minute: parseFloat(endMinute),
    };

    return values;
  };
}

export const ScheduleIntervalQueries = new ScheduleIntervalQueryClient();
