import { format } from "date-fns";

import { QueryKeys } from "@/types";
import { DATE_FORMAT } from "@/lib/formatters";

import { BaseQueryClient } from "../base/queries";

import { HolidaySchedule } from "./types";

const queryKey = QueryKeys.HolidaySchedules;

class HolidayScheduleClient extends BaseQueryClient<HolidaySchedule> {
  constructor() {
    super({
      queryKey,
      afterModify: async (queryClient) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.SiteAlerts],
        });
      },
    });
  }

  decode = (values: any) => {
    values.start_at = new Date(values.start_at);
    values.end_at = new Date(values.end_at);
    return values;
  };

  encode = (values: any) => {
    values.start_at = format(values.start_at, DATE_FORMAT);
    values.end_at = format(values.end_at, DATE_FORMAT);
    return values;
  };
}

export const HolidayScheduleQueries = new HolidayScheduleClient();
