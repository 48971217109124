import { BaseRecord } from "../base/types";

export interface ScheduleInterval extends BaseRecord {
  schedule_id?: number;
  days_mask?: number;
  start_time: { hour: number; minute: number };
  end_time: { hour: number; minute: number };
  inserted_at?: string;
  updated_at?: string;
  days_list?: ScheduleIntervalDayMask[];
}

enum ScheduleIntervalDayMask {
  Sunday = "1",
  Monday = "2",
  Tuesday = "4",
  Wednesday = "8",
  Thursday = "16",
  Friday = "32",
  Saturday = "64",
}

export const ValidScheduleIntervals = [
  ScheduleIntervalDayMask.Sunday,
  ScheduleIntervalDayMask.Monday,
  ScheduleIntervalDayMask.Tuesday,
  ScheduleIntervalDayMask.Wednesday,
  ScheduleIntervalDayMask.Thursday,
  ScheduleIntervalDayMask.Friday,
  ScheduleIntervalDayMask.Saturday,
];

export const ScheduleIntervalDayMaskOptions = [
  { label: "Sunday", value: ScheduleIntervalDayMask.Sunday },
  { label: "Monday", value: ScheduleIntervalDayMask.Monday },
  { label: "Tuesday", value: ScheduleIntervalDayMask.Tuesday },
  { label: "Wednesday", value: ScheduleIntervalDayMask.Wednesday },
  { label: "Thursday", value: ScheduleIntervalDayMask.Thursday },
  { label: "Friday", value: ScheduleIntervalDayMask.Friday },
  { label: "Saturday", value: ScheduleIntervalDayMask.Saturday },
];

export const HumanReadableDayMask = {
  [ScheduleIntervalDayMask.Sunday]: "Sunday",
  [ScheduleIntervalDayMask.Monday]: "Monday",
  [ScheduleIntervalDayMask.Tuesday]: "Tuesday",
  [ScheduleIntervalDayMask.Wednesday]: "Wednesday",
  [ScheduleIntervalDayMask.Thursday]: "Thursday",
  [ScheduleIntervalDayMask.Friday]: "Friday",
  [ScheduleIntervalDayMask.Saturday]: "Saturday",
};
